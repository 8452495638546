import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    app_title_page : 'Saleonline',
    loginStatus : 0,
  },
  mutations: {
    setTitlePage(state,value){
      state.app_title_page = value;
    },
    setLogin(state,value){
      state.loginStatus = value
    },
  },
  actions: {
    loginSuccess(context){
      context.commit('setLogin',1)
    },
    logOut(context){
      context.commit('setLogin',0)
    },
  },
  modules: {
  }
})
