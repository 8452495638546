


































import { Component, Vue } from 'vue-property-decorator';
@Component
export default class BottomNav extends Vue {
  //activeItem: string = 'top';
}
