<template>

   <v-container>
    <v-subheader> <h1 class="headline">Setting</h1></v-subheader>
    <v-divider></v-divider>
   <v-row no-gutters>
        <v-col
          lg="6"
          md="6"
          sm="6"
          offset-sm="3"
          xs="12"
          :align-self="center"
        >
         <v-card
            class="pa-2"
            color="#cde4ef"
          >
         <v-subheader> 
            <v-icon>mdi-account</v-icon> &nbsp;&nbsp;UDID
        </v-subheader>
         <v-divider></v-divider>
           <p style="text-align:center">{{udid}}</p>
           <p  style="text-align:center;color:#FF0000;" v-bind:style="[msg =='Approved' ? {'color':'#0000FF'}:{'color':'#FF0000'}]">{{msg}}</p>
          </v-card>
          </v-col>
           
  </v-row>
  <v-row
   align="center"
   v-if="is_request"
  >
      <v-col
            cols="12"
            sm="12"
            align="center"
          >
      <v-btn
          color="primary"
          @click="dialog = true"
        >
          Request For Approve
        </v-btn>
      </v-col>
  </v-row>
  <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
      <v-card>
          <v-card-title>
            <span class="text-h5">Request Form</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="UDID"
                    required
                    v-model="udid"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="remark"
                    v-model="remark"
                  ></v-text-field>
                </v-col>
                
                
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              @click="requestApprove()"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
  </v-dialog>

  <v-dialog
        v-model="dialog_loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Please wait!
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>


  
  </v-container>

</template>
<script>
import FingerprintJS from '@fingerprintjs/fingerprintjs'
import VueCookies from 'vue-cookies';
import axios from "axios"
export default {
   data() {
        return {
          udid: '',
          remark: '',
          msg:'',
          data_res: '',
          dialog: false,
          is_request: false,
          dialog_loading: false,
        }
    },
    mounted() {
       this.chkCookies();
       this.$store.commit("setTitlePage","Sales Online");
    },
     methods: {
        getFngerprint(){
             FingerprintJS.load().then(fp => {
            // The FingerprintJS agent is ready.
            // Get a visitor identifier when you'd like to.
              fp.get().then(result => {
                  // This is the visitor identifier:
                  const visitorId = result.visitorId;
                  console.log(visitorId);
                  this.udid = visitorId;
                  
                  //this.chkCookies();
                  if(!VueCookies.isKey('allow_code')){
                    this.chkAllow();
                  }else{
                    this.msg = "Approved";
                  }
                  
              });
           });
        },
        chkCookies(){
          console.log(VueCookies.isKey('fingerprint'));  
          if(VueCookies.isKey('fingerprint')){
            console.log("use cookies");
            console.log("cookies = "+VueCookies.get('fingerprint'));
            this.udid = VueCookies.get('fingerprint');
            this.chkAllow();
          }else{
            console.log("cookies not found");  
            this.getFngerprint();
            //this.$router.push("/Settings");
          }
        }, 
        chkAllow(){
            axios.post("https://sol-mobile-api.kingpower.com/chkDevice?udid="+this.udid+"&remark=").then(res => {
                    this.data_res = res.data;
                    console.log(this.data_res) 
                    if(this.data_res.status == "not found"){
                      this.msg = "You is not request for approve";
                      this.is_request = true;
                    }else if(this.data_res.iPhoneAllowed == true){
                        VueCookies.set("fingerprint",this.udid,'1000d');
                        this.msg = "Approved";
                        localStorage.setItem('login_status', "success");
                        this.is_request = false;
                        this.$store.dispatch("loginSuccess");
                    }else{
                        localStorage.setItem('login_status', "");
                         this.msg = "Waiting for approve";
                         this.is_request = false;
                    }  
                              
            })
            .catch(error => {
                    this.$swal( 'Error',error.response.data,'error');
            });
        },
        requestApprove(){
           this.dialog = false;
           this.dialog_loading = true;
            axios.post("https://sol-mobile-api.kingpower.com/deviceRequest?udid="+this.udid+"&remark="+this.remark).then(res => {
                    this.$swal( '','Waiting for approve','info');
                    this.data_res = res.data;
                    //console.log(this.data_res) 
                    if(this.data_res.iPhoneAllowed == true){
                        this.msg = "Approved";
                        this.dialog = false;
                        this.dialog_loading = false;
                        this.is_request = false;
                        this.$store.dispatch("loginSuccess");
                    }else{
                         this.msg = "Waiting for approve";
                         this.dialog = false;
                         this.dialog_loading = false;
                         this.is_request = false;
                    }  
                              
            })
            .catch(error => {
                    this.dialog_loading = false;
                    this.$swal( 'Error',error.response.data,'error');
            });
        }
    },
  
}
</script>

