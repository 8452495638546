






















































































import { Component, Vue } from 'vue-property-decorator';
@Component
export default class TopToolbar extends Vue {
  showMenu: boolean = false;
  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }
  doNothing(): void {
    console.log("Click");
  }
}
