<template>
  <v-container>
  <AllowDevice/>
  <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Sales Data Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu2 = false"
              :max="nowDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
  </v-row>
   <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }">
   <v-icon>mdi-cart</v-icon>&nbsp;&nbsp;Summary
   </v-subheader>
   <v-row no-gutters v-if="is_show">
        <v-col
          cols="4"
          sm="4"
        >
          <v-card
            class="pa-2"
            outlined
            color="blue"
            :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
          >
           Gross <br> {{sum_gross}}
          </v-card>
        </v-col>
        <v-col
          cols="4"
          sm="4"
        >
          <v-card
            class="pa-2"
            outlined
            color="red"
            :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
          >
            Discount <br> {{sum_discount}}
          </v-card>
        </v-col>
        <v-col
          cols="4"
          sm="4"
        >
          <v-card
            class="pa-2"
            outlined
            color="green"
            :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
          >
           Net <br> {{sum_net}}
          </v-card>
        </v-col>
      </v-row>
    <div style="margin: 20px 0px 20px 0px;">
    <v-card 
     color="#cde4ef"
     hover
     v-if="is_show">
    <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }"> 
      <v-icon>mdi-chart-pie</v-icon> &nbsp;&nbsp;Chart
    </v-subheader>
    <v-divider></v-divider>
      <div id="chart"  v-if="is_show" style="max-width:700px !important">
        <apexchart type="pie" width="100%" :options="chartOptions" :series="series"></apexchart>
      </div>
       </v-card>
    </div>
   
    <div style="margin: 20px 0px 20px 0px;">
    <v-card 
    color="#1283b9"
     v-if="is_show">
    <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }" style="color:#FFFFFF">
    <v-icon color="#FFFFFF">mdi-chart-areaspline</v-icon>&nbsp;&nbsp;Company
    </v-subheader>
    <v-divider></v-divider>
    <v-simple-table v-if="is_show">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              Company
            </th>
            <th class="text-center">
              Gross
            </th>
            <th class="text-center">
              Discount
            </th>
             <th class="text-center">
              Net
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in saledatagroup"
            :key="item.ShortName"
             @click="showDetail(index)"
          >
            <td>{{ index }}</td>
            <td style='text-align: right;color:blue'>{{ saleSum(item,'Amount') }}</td>
            <td style='text-align: right;color:red'>{{ saleSum(item,'Discount') }}</td>
            <td style='text-align: right;color:green'>{{ saleSum(item,'Net') }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-card>
   </div>
    <v-dialog
        v-model="dialog_loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Please wait!
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
         
        >
          <v-card>
            <v-toolbar
              flat
              dark
              color="primary"
               :class="{ 'my-toolbar': $vuetify.breakpoint.smAndDown }"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{company}}</v-toolbar-title>                      
            </v-toolbar>
            <v-card-text  :height=windowHeight>
            <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }">
            <v-icon>mdi-cart</v-icon>&nbsp;&nbsp;Summary
            </v-subheader>
   
               <v-row no-gutters v-if="is_show">
                    <v-col
                      cols="4"
                      sm="4"
                    >
                      <v-card
                        class="pa-2"
                        outlined
                        color="blue"
                        :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
                      >
                      Gross <br> {{sum_sub_gross}}
                      </v-card>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                    >
                      <v-card
                        class="pa-2"
                        outlined
                        color="red"
                        :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
                      >
                        Discount <br> {{sum_sub_discount}}
                      </v-card>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                    >
                      <v-card
                        class="pa-2"
                        outlined
                        color="green"
                        :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
                      >
                      Net <br> {{sum_sub_net}}
                      </v-card>
                    </v-col>
              </v-row>
               <v-divider></v-divider>
               <div style="margin: 20px 0px 20px 0px;">
               <v-card 
               color="#1283b9"
               >
               <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }" style="color:#FFFFFF">
               <v-icon color="#FFFFFF">mdi-image-area</v-icon>&nbsp;&nbsp;Location</v-subheader>
              <v-simple-table :height=windowHeight>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            LocCode
                          </th>
                          <th class="text-center">
                            Gross
                          </th>
                          <th class="text-center">
                            Discount
                          </th>
                          <th class="text-center">
                            Net
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in locdatagroup"
                          :key="item.LocCode"
                          @click="showDetail2(index)"
                        >
                          <td>{{ index }}</td>
                          <td style='text-align: right;color:blue'>{{ saleSum(item,'Amount') }}</td>
                          <td style='text-align: right;color:red'>{{ saleSum(item,'Discount') }}</td>
                          <td style='text-align: right;color:green'>{{ saleSum(item,'Net') }}</td>
                        </tr>
                      </tbody>
                    </template>
            </v-simple-table>
             </v-card>
             </div>
            </v-card-text>
          </v-card>
        </v-dialog>


        <v-dialog
          v-model="dialog2"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
         
        >
          <v-card>
            <v-toolbar
              flat
              dark
              color="primary"
               :class="{ 'my-toolbar': $vuetify.breakpoint.smAndDown }"
            >
              <v-btn
                icon
                dark
                @click="dialog2 = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{catecode}}</v-toolbar-title>                      
            </v-toolbar>
            <v-card-text  :height=windowHeight>
            <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }">
            <v-icon>mdi-cart</v-icon>&nbsp;&nbsp;Summary
            </v-subheader>
   
               <v-row no-gutters v-if="is_show">
                    <v-col
                      cols="4"
                      sm="4"
                    >
                      <v-card
                        class="pa-2"
                        outlined
                        color="blue"
                        :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
                      >
                      Gross <br> {{sum_sub_gross_cate}}
                      </v-card>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                    >
                      <v-card
                        class="pa-2"
                        outlined
                        color="red"
                        :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
                      >
                        Discount <br> {{sum_sub_discount_cate}}
                      </v-card>
                    </v-col>
                    <v-col
                      cols="4"
                      sm="4"
                    >
                      <v-card
                        class="pa-2"
                        outlined
                        color="green"
                        :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
                      >
                      Net <br> {{sum_sub_net_cate}}
                      </v-card>
                    </v-col>
              </v-row>
               <v-divider></v-divider>
               <div style="margin: 20px 0px 20px 0px;">
               <v-card 
               color="#1283b9"
               >
               <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }" style="color:#FFFFFF">
               <v-icon color="#FFFFFF">mdi-image-area</v-icon>&nbsp;&nbsp;Location</v-subheader>
              <v-simple-table :height=windowHeight>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Category
                          </th>
                          <th class="text-center">
                            Gross
                          </th>
                          <th class="text-center">
                            Discount
                          </th>
                          <th class="text-center">
                            Net
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in catedatagroup"
                          :key="item.CateCode"
                        >
                          <td>{{ item[0]['CateName'] }}</td>
                          <td style='text-align: right;color:blue'>{{ saleSum(item,'Amount') }}</td>
                          <td style='text-align: right;color:red'>{{ saleSum(item,'Discount') }}</td>
                          <td style='text-align: right;color:green'>{{ saleSum(item,'Net') }}</td>
                        </tr>
                      </tbody>
                    </template>
            </v-simple-table>
             </v-card>
             </div>
            </v-card-text>
          </v-card>
        </v-dialog>

  </v-container>

</template>

<script>
import axios from "axios"
import VueApexCharts from 'vue-apexcharts'
import AllowDevice from '../components/AllowDevice.vue'
export default {
  components: {
          apexchart: VueApexCharts,
          AllowDevice,
        },
   data() {
        return {
           sum_gross: 0,
           sum_discount:0,
           sum_net:0,
           sum_sub_gross: 0,
           sum_sub_discount:0,
           sum_sub_net:0,
           sum_sub_gross_cate: 0,
           sum_sub_discount_cate:0,
           sum_sub_net_cate:0,
           company:'',
           is_show : false,
           dialog_loading: false,
           dialog: false,
           dialog2: false,
           saledata : null,
           saledatagroup: null,
           locdatagroup: null,
           catedatagroup: null,
           windowHeight:'',
           txt_height:'',
           series: [],
           chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: [],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 400
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
           nowDate: new Date().toISOString().slice(0,10),
           date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            dateFormatted:'',
            menu1: false,
            menu2: false,
            datadate:''
        }
    },
    mounted() {
      this.windowHeight = window.innerHeight;
      this.windowHeight =this.windowHeight - 230;
      this.$store.commit("setTitlePage","Sales Online");
    },
    computed: {
      computedDateFormatted () {
       if(this.$store.state.loginStatus == 1){
          const [year, month, day]  = this.date.split('-');
          //console.log(`${year}${month}${day}`); 
          this.getSales(`${year}${month}${day}`); 
       }
        return this.formatDate(this.date)
      },
  },

  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
    methods: {
      formatDate (date) {
        if (!date) return null
        
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      getSales(datadate){
        this.dialog_loading = true;
        this.is_show = false;
        this.sum_gross = 0;
        this.sum_discount = 0;
        this.sum_net = 0;
        axios.post("https://sol-mobile-api.kingpower.com/getSale?method=getsale&datadate="+datadate).then(res => {
                    this.saledata = res.data;
                    //console.log(this.saledata) 
                    this.saleGroup(this.saledata,"ShortName")   
                              
            })
            .catch(error => {
                    this.dialog_loading = false;
                    this.$swal( 'Error',error.response.data,'error');
            });
            
      },
      saleGroup(array,keys){
          const result = {};
          const label = [];
          const serie = [];
          array.forEach(item => {
            if (!result[item[keys]]){
              result[item[keys]] = [];
              //console.log(item[keys]);
              label.push(item[keys]);            
              //console.log(result[item[keys]]);      
            }
            result[item[keys]].push(item)
           
          });
          //console.log(result);
          this.saledatagroup = result;
          this.dialog_loading = false;
          this.is_show = true;
          // console.log(this.series);
          // console.log(this.chartOptions);
          label.forEach(item => {
              //console.log(item)
              serie.push(this.graphseries(this.saledatagroup[item],'Net'));
              this.sum_gross += this.graphseries(this.saledatagroup[item],'Amount');
              this.sum_discount += this.graphseries(this.saledatagroup[item],'Discount');
              this.sum_net += this.graphseries(this.saledatagroup[item],'Net');
          });
         this.series = serie;
          this.chartOptions = {
              labels: label,
         };
        //  console.log(this.sum_gross);
        //  console.log(this.sum_discount);
        //  console.log(this.sum_net);
         this.sum_gross = this.numberWithCommas(this.sum_gross);
         this.sum_discount = this.numberWithCommas(this.sum_discount);
         this.sum_net = this.numberWithCommas(this.sum_net);
      },
      saleSum(item,keys){
          var sum = 0;
          item.forEach(item => {
              sum += item[keys]
          });
        return this.numberWithCommas(sum);
      },
      graphseries(array,keys){
          var sum = 0;
           array.forEach(item => {
              //console.log(item)
              sum += item[keys]
          });
        return sum;
      },
      numberWithCommas(number) {
        let nf = new Intl.NumberFormat('en-US', {maximumFractionDigits:0});
        return nf.format(number);
      },
      showDetail(company){
        console.log(company);
        this.company = company;
        console.log(this.saledatagroup[company])
        this.locGroup(this.saledatagroup[company],'LocCode')
      },
      showDetail2(catecode){
       //console.log(catecode);
        this.catecode = catecode;
        //console.log(this.locdatagroup[catecode])
        this.cateGroup(this.locdatagroup[catecode],'CateCode')
      },
      locGroup(array,keys){
        const result = {};
        const locCode = [];
        this.sum_sub_gross = 0;
        this.sum_sub_discount = 0;
        this.sum_sub_net = 0;
          array.forEach(item => {
            if (!result[item[keys]]){
              result[item[keys]] = []
              locCode.push(item[keys]);
            }
            result[item[keys]].push(item)
          });
          //console.log("====== popup 2 ===========");
          //console.log(result);
          this.locdatagroup = result;
          this.dialog = true;
          locCode.forEach(item => {
              //console.log(item)
              this.sum_sub_gross += this.graphseries(this.locdatagroup[item],'Amount');
              this.sum_sub_discount += this.graphseries(this.locdatagroup[item],'Discount');
              this.sum_sub_net += this.graphseries(this.locdatagroup[item],'Net');
          });
          this.sum_sub_gross = this.numberWithCommas(this.sum_sub_gross);
          this.sum_sub_discount = this.numberWithCommas(this.sum_sub_discount);
          this.sum_sub_net = this.numberWithCommas(this.sum_sub_net);
      },
      cateGroup(array,keys){
        const result = {};
        const locCode = [];
        this.sum_sub_gross_cate = 0;
        this.sum_sub_discount_cate = 0;
        this.sum_sub_net_cate = 0;
          array.forEach(item => {
            if (!result[item[keys]]){
              result[item[keys]] = []
              locCode.push(item[keys]);
            }
            result[item[keys]].push(item)
          });
          console.log("====== popup 3 ===========");
          console.log(result);
          this.catedatagroup = result;
          this.dialog2 = true;
          locCode.forEach(item => {
              //console.log(item)
              this.sum_sub_gross_cate += this.graphseries(this.catedatagroup[item],'Amount');
              this.sum_sub_discount_cate += this.graphseries(this.catedatagroup[item],'Discount');
              this.sum_sub_net_cate += this.graphseries(this.catedatagroup[item],'Net');
          });
          this.sum_sub_gross_cate = this.numberWithCommas(this.sum_sub_gross_cate);
          this.sum_sub_discount_cate = this.numberWithCommas(this.sum_sub_discount_cate);
          this.sum_sub_net_cate = this.numberWithCommas(this.sum_sub_net_cate);
      },
      chkAllow(){
         if(localStorage.getItem('login_status') == "success"){
              //console.log(localStorage.getItem('login_status'))
              this.authenticated = true;
          }else{
              if(!this.authenticated) {
                  this.$router.push("/Settings");
              }
          }
      }
      
    },

}
</script>
<style>
.detail_dialog { min-height:1000px}
.subheader{font-size:20px !important;}
.summary{ color:#FFFFFF !important; font-weight:bold;margin:1px}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
  color:blue !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead{
  background:#cde4ef !important;
}
</style>