import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
//import Home from '../views/Home.vue'

//import TopStories from '../views/TopStories.vue';
//import CodeExamples from '../views/CodeExamples.vue';
//import MyFavorites from '../views/MyFavorites.vue';

import Salesonline from '../views/Salesonline.vue';
import SalesonlineCate from '../views/SalesonlineCate.vue';
import SalesonlineMember from '../views/SalesonlineMember.vue';
import SalesonlinePos from '../views/SalesonlinePos.vue';
import SalesonlinePreorder from '../views/SalesonlinePreorder.vue';
import Settings from '../views/Setting.vue';

import Webonline from '../views/Webonline.vue';
import Promotion from '../views/Promotion.vue';
import Shopping from '../views/Shopping.vue';
import Member from '../views/Member.vue';


Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Salesonline',
    component: Salesonline
  },
  {
    path: '/kingpower',
    name: 'kingpower',
    component: Webonline
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: Promotion
  },
  {
    path: '/shopping',
    name: 'shopping',
    component: Shopping
  },
  {
    path: '/member',
    name: 'member',
    component: Member
  },
  {
    path: '/SalesonlinePos',
    name: 'SalesonlinePos',
    component: SalesonlinePos
  },
  {
    path: '/SalesonlineCate',
    name: 'SalesonlineCate',
    component: SalesonlineCate
  },
  {
    path: '/SalesonlineMember',
    name: 'SalesonlineMember',
    component: SalesonlineMember
  },
  {
    path: '/SalesonlinePreorder',
    name: 'SalesonlinePreorder',
    component: SalesonlinePreorder
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: Settings
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
