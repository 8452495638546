<template>

  <v-container>
    <v-card>
      <iframe  frameborder="0" 
    marginheight="0" 
    marginwidth="0" 
    width="100%" 
    :height=windowHeight 
    src="http://story.kingpower.com/th/shopping-th/"></iframe>
    </v-card>
    
  </v-container>

</template>
<script>
export default {
   data() {
        return {
           windowHeight:'',
            txt: ''
        }
    },
    mounted() {
       this.windowHeight = window.innerHeight -100;
       console.log(this.windowHeight);
       this.$store.commit("setTitlePage","Kingpower Shopping");
    }
}
</script>

