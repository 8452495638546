<template>
  <v-container>
  <AllowDevice/>
  <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-menu
            v-model="menu2"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedDateFormatted"
                label="Sales Data Date"
                persistent-hint
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              no-title
              @input="menu2 = false"
              :max="nowDate"
            ></v-date-picker>
          </v-menu>
        </v-col>
  </v-row>
  <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }">
   <v-icon>mdi-cart</v-icon>&nbsp;&nbsp;Summary
   </v-subheader>
   <v-row no-gutters v-if="is_show" >
       
        <v-col
          cols="4"
          sm="4"
        >
          <v-card
            class="pa-2"
            outlined
            color="green"
            :class="{ 'summary': $vuetify.breakpoint.smAndDown }"
          >
           TOTAL <br> {{total}}
          </v-card>
        </v-col>
      </v-row>
    <div style="margin: 20px 0px 20px 0px;">
    <v-card 
     color="#cde4ef"
     hover
     v-if="is_show">
    <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }"> 
      <v-icon>mdi-chart-pie</v-icon> &nbsp;&nbsp;Chart
    </v-subheader>
    <v-divider></v-divider>
      <div id="chart"  v-if="is_show" style="max-width:700px !important">
        <apexchart type="pie" width="100%" :options="chartOptions" :series="series"></apexchart>
      </div>
       </v-card>
    </div>
   
    <div style="margin: 20px 0px 20px 0px;">
    <v-card 
    color="#1283b9"
     v-if="is_show">
    <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }" style="color:#FFFFFF">
    <v-icon color="#FFFFFF">mdi-chart-areaspline</v-icon>&nbsp;&nbsp;Company
    </v-subheader>
    <v-divider></v-divider>
    <v-simple-table v-if="is_show">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              Site
            </th>
            <th class="text-center">
              Gross
            </th>
            <th class="text-center">
              Memer Discount
            </th>
             <th class="text-center">
              Net
            </th>
            <th class="text-center">
              Gross
            </th>
            <th class="text-center">
              NON-Memer Discount
            </th>
             <th class="text-center">
              Net
            </th>
             <th class="text-center">
              Total
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item) in saledatagroup"
            :key="item.Site_ID"
             @click="showDetail(item.Site_ID)"
            
          >
            <td style='text-align: left' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ item.Site_Name }}</td>
            <td style='text-align: right;color:blue' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ numberWithCommas(item.M_Gross) }}</td>
            <td style='text-align: right;color:red' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ numberWithCommas(item.M_Discount) }}</td>
            <td style='text-align: right;color:green' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ numberWithCommas(item.M_Net) }}</td>
            <td style='text-align: right;color:blue' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ numberWithCommas(item.NM_Gross) }}</td>
            <td style='text-align: right;color:red' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ numberWithCommas(item.NM_Discount) }}</td>
            <td style='text-align: right;color:green' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ numberWithCommas(item.NM_Net) }}</td>
            <td style='text-align: right;color:orange' v-bind:class= "[ item.Site_Name==='TOTAL' ?  'total':'']">{{ numberWithCommas(item.Total_Net) }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    </v-card>
   </div>
    <v-dialog
        v-model="dialog_loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Please wait!
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="dialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
          scrollable
         
        >
          <v-card>
            <v-toolbar
              flat
              dark
              color="primary"
               :class="{ 'my-toolbar': $vuetify.breakpoint.smAndDown }"
            >
              <v-btn
                icon
                dark
                @click="dialog = false"
              >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{company}}</v-toolbar-title>                      
            </v-toolbar>
            <v-card-text  :height=windowHeight>
            
               <div style="margin: 20px 0px 20px 0px;">
               <v-card 
               color="#1283b9"
               >
               <v-subheader v-if="is_show" :class="{ 'subheader': $vuetify.breakpoint.smAndDown }" style="color:#FFFFFF">
               <v-icon color="#FFFFFF">mdi-image-area</v-icon>&nbsp;&nbsp;Member Type</v-subheader>
              <v-simple-table :height=windowHeight>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-center">
                            Type
                          </th>
                          <th class="text-center">
                            Gross
                          </th>
                          <th class="text-center">
                            Discount
                          </th>
                          <th class="text-center">
                            Net
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item) in member"
                          :key="item.member_type"
                        >
                          <td>{{item.member_type}}</td>
                          <td style='text-align: right;color:blue'>{{numberWithCommas(item.gross)}}</td>
                          <td style='text-align: right;color:red'>{{numberWithCommas(item.discount)}}</td>
                          <td style='text-align: right;color:green'>{{numberWithCommas(item.net)}}</td>
                        </tr>
           
                      </tbody>
                    </template>
            </v-simple-table>
             </v-card>
             </div>
            </v-card-text>
          </v-card>
        </v-dialog>

  </v-container>

</template>

<script>
import axios from "axios"
import VueApexCharts from 'vue-apexcharts'
import AllowDevice from '../components/AllowDevice.vue'
export default {
  components: {
          apexchart: VueApexCharts,
          AllowDevice
        },
   data() {
        return {
           company:'',
           total: 0,
           is_show : false,
           dialog_loading: false,
           dialog: false,
           saledata : null,
           saledatagroup: null,
           member: null,
           windowHeight:'',
           txt_height:'',
           series: [],
           chartOptions: {
            chart: {
              width: 380,
              type: 'pie',
            },
            labels: [],
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 400
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
           nowDate: new Date().toISOString().slice(0,10),
           date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            //dateFormatted: vm.formatDate((new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10)),
            dateFormatted:'',
            menu1: false,
            menu2: false,
            datadate:''
        }
    },
    mounted() {
      this.windowHeight = window.innerHeight;
      this.windowHeight =this.windowHeight - 150;
      this.$store.commit("setTitlePage","Sales Online Member");
      //this.getSales();
    },
    computed: {
      computedDateFormatted () {
        if(this.$store.state.loginStatus == 1){
          this.getSales(this.date); 
        }
        return this.formatDate(this.date)
      }
  },

  watch: {
    date (val) {
      this.dateFormatted = this.formatDate(this.date)
    },
  },
    methods: {
      formatDate (date) {
        if (!date) return null
        
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate (date) {
        if (!date) return null

        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
      getSales(datadate){
        this.dialog_loading = true;
        this.is_show = false;
        axios.post("https://sol-mobile-api.kingpower.com/getSaleMember?datadate="+datadate).then(res => {
                    this.saledata = res.data;
                    //console.log(this.saledata.GetSaleByDateGroupBySiteResult); 
                    this.saleGroup(this.saledata.GetSaleByDateGroupBySiteResult);   
                              
            })
            .catch(error => {
                    this.dialog_loading = false;
                    this.$swal( 'Error',error.response.data,'error');
            });
            
      },
      saleGroup(array){
          const label = [];
          const serie = [];
          
          this.saledatagroup = array;
          this.dialog_loading = false;
          this.is_show = true;
          array.forEach(item => {
              //console.log(item)
              if(item.Site_Name != "TOTAL"){
                serie.push(item.Total_Net);  
                label.push(item.Site_Name);
              }else if(item.Site_Name == "TOTAL"){
                this.total = this.numberWithCommas(item.Total_Net);
              }
              
          });
          //console.log(serie);
          this.series = serie;
          this.chartOptions = {
              labels: label,
         };
        //  console.log(this.sum_gross);
        //  console.log(this.sum_discount);
        //  console.log(this.sum_net);
        //  this.sum_gross = this.numberWithCommas(this.sum_gross);
        //  this.sum_discount = this.numberWithCommas(this.sum_discount);
        //  this.sum_net = this.numberWithCommas(this.sum_net);
      },
      saleSum(item,keys){
          var sum = 0;
          item.forEach(item => {
              sum += item[keys]
          });
        return this.numberWithCommas(sum);
      },
      numberWithCommas(number) {
        let nf = new Intl.NumberFormat('en-US', {maximumFractionDigits:0});
        return nf.format(number);
      },
      showDetail(site_id){
       const result = [];
       const arr_m = {};
       const arr_nm = {};
       if(site_id != "4555"){
        this.saledatagroup.forEach(item => {
                //console.log(site_id)
                
                if(item.Site_ID == site_id){
                  this.company = site_id +" "+item.Site_Name;
                  if(item.M_Net != null){
                    arr_m['member_type'] = "Member";
                    arr_m['gross'] = item.M_Gross;
                    arr_m['discount'] = item.M_Discount;
                    arr_m['net'] = item.M_Net;
                    result.push(arr_m);
                  }

                  if(item.NM_Net != null){
                    arr_nm['member_type'] = "Non-Member";
                    arr_nm['gross'] = item.NM_Gross;
                    arr_nm['discount'] = item.NM_Discount;
                    arr_nm['net'] = item.NM_Net;
                    result.push(arr_nm);
                  }
                
                }  
                
          });
          this.member = result;
          //console.log(this.member);
          this.dialog = true;
       }
      },
     
    },

}
</script>
<style>
.detail_dialog { min-height:1000px}
.subheader{font-size:20px !important;}
.summary{ color:#FFFFFF !important; font-weight:bold;margin:1px}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
  color:blue !important;
}
.theme--light.v-data-table > .v-data-table__wrapper > table > thead{
  background:#cde4ef !important;
}
.total{font-weight:bold;}
</style>