














  import { Component, Vue } from 'vue-property-decorator';
  import TopToolbar from './components/TopToolbar.vue';
  import BottomNav from './components/BottomNav.vue';
  import FingerprintJS from '@fingerprintjs/fingerprintjs';
  import VueCookies from 'vue-cookies';
  import axios from "axios"

  export default{
    data() {
        return {
          msg: '',
          authenticated: false,
          //status : false
        }
    },
    components: {
      TopToolbar,
      BottomNav
    },
    mounted() {
      //this.chkCookies();
    },
    methods: {
        // setAuthenticated(this.status) {
        //   this.authenticated = this.status;
        // },
        // logout() {
        //     localStorage.setItem('login_status', "")
        //     this.authenticated = false;
        // },
    },
}
