<template lang="">
  <div>


    <v-row justify="center">
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
      >
        <template>
          <v-btn
            color="primary"
            dark
            v-bind="attrs"
            v-on="on"
          >
            เปิดใช้งานเพื่อเข้าระบบ
          </v-btn>
        </template>
        <v-card>
          <v-card-title>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                  md="4"
                >
                  <v-text-field
                    label="password"
                     type="password"
                    v-model="password"
                    required
                  ></v-text-field>
                </v-col>
    
              </v-row>        
              <v-row >
              <v-col
                  cols="4"
                  sm="4"
                  >
                   <v-btn
                      color="primary"
                       style="font-size:11px"
                       width=90
                      @click="chkPassword"
                    >
                      เปิดใช้งาน
                    </v-btn>
                  </v-col>
                <v-col
                  cols="4"
                  sm="4"
                  >
                   <v-btn
                      color="primary"
                       style="font-size:11px"
                       width=90
                      @click="toSetting"
                    >
                      ยื่นคำร้อง
                    </v-btn>
                  </v-col>
                    <v-col
                  cols="4"
                  sm="4"
                  >
                   <v-btn
                      color="default"
                       style="font-size:11px"
                       width=90
                      @click="dialog = false"
                    >
                      ยกเลิก
                    </v-btn>
                  </v-col>
              </v-row>
            </v-container>
          </v-card-text>
     
        </v-card>
      </v-dialog>
    </v-row>

    <v-dialog
        v-model="dialog_loading"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="primary"
          dark
        >
          <v-card-text>
            Please wait check device!
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            ></v-progress-linear>
          </v-card-text>
        </v-card>
      </v-dialog>


      <v-dialog
          v-model="alert_warning"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
            <span>{{warning_title}}</span>
              <v-spacer></v-spacer>
              <v-menu
                bottom
                left
              >
               
              </v-menu>
            </v-card-title>
            <v-card-actions>
              <v-btn
                color="primary"
                text
                @click="alert_warning = false"
              >
                Close
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>


  </div>
</template>
<script>
import axios from "axios"
import VueCookies from 'vue-cookies';
export default {
    data(){
        return{
          dialog_loading: false,
          dialog: false,
          password: "",
          alert_warning: false,
          warning_title: "",
          
        }
    },
    mounted() {
      this.chkCookies();
    },
    methods:{
        chkCookies(){
          this.dialog_loading = true;
          if(VueCookies.isKey('fingerprint')){
            console.log("use cookies");
            axios.post("https://sol-mobile-api.kingpower.com/chkDevice?udid="+VueCookies.get('fingerprint')+"&remark=").then(res => {
                    this.data_res = res.data;
                    // console.log(this.data_res); 
                    // console.log(this.data_res.iPhoneAllowed);
                    this.dialog_loading = false;
                    if(this.data_res.status == "not found"){
                        localStorage.setItem('login_status', "");
                        this.authenticated = false
                        this.$store.dispatch("logOut");
                         if(!this.authenticated) {
                            this.$router.push("/Settings");
                         }
                    }else if(this.data_res.iPhoneAllowed == true){
                        localStorage.setItem('login_status', "success");
                        VueCookies.set("fingerprint",VueCookies.get('fingerprint'),'1000d');
                        this.authenticated = true;
                        this.$store.dispatch("loginSuccess");
                    }else{
                        localStorage.setItem('login_status', "");
                        this.authenticated = false;
                        this.$store.dispatch("logOut");
                         if(!this.authenticated) {
                            this.$router.push("/Settings");
                         }
                    }  
                              
            })
            .catch(error => {
                    this.$swal( 'Error',error.response.data,'error');
                    this.dialog_loading = false;
            });
          }else if(VueCookies.isKey('allow_code')){
               VueCookies.set("allow_code",this.password,'1000d');
              this.dialog_loading = false;
              localStorage.setItem('login_status', "success");
              this.authenticated = true;
              this.$store.dispatch("loginSuccess");
          } else {
            console.log("cookies not found"); 
            this.dialog_loading = false; 
            this.dialog = true;
            //this.$router.push("/Settings");
          }
        }, 
        toSetting(){
          this.$router.push("/Settings");
        },
        chkPassword(){
          var data = null;
          if(this.password == ""){
            this.$swal( 'Warning',"กรุณาใส่ password",'warning');
          }else{
             this.dialog_loading = true;
            axios.get("https://sol-mobile-api.kingpower.com/getCode?code="+this.password).then(res => {
                    data = res.data;
                    console.log(data); 
                    // console.log(this.data_res.iPhoneAllowed);
                    this.dialog_loading = false;
                    if(data.detail != undefined){
                      this.$swal( 'Error',data.detail,'error');
                    }else{
                      console.log("pass");
                       this.dialog = false;
                       VueCookies.set("allow_code",this.password,'1000d');
                       this.chkCookies();
                    }
            
            })
            .catch(error => {
                    this.$swal( 'Error',error.response.data,'error');
                    this.dialog_loading = false;
            });
          }
        } 
    }
}
</script>

<style lang="">
    
</style>